import React from 'react'

import styles from "./policies-css-module.module.scss"
import '../styles.scss'
import { Layout } from '../components'
import TermsOfServices from '../components/policies/termsOfServices/TermsOfServices'

import { Link } from "gatsby"

const TermsOfService = () => {
    return (
        <>
        <Layout>
            <div className={styles.container}>
                <div className={styles.link_section}>
                    <div className={styles.link_section_div}>
                        <Link exact className={styles.tc_links} activeClassName='is-active' to='/terms-of-services/'>Terms Of Services</Link>
                    </div>
                    <div className={styles.link_section_div}>
                        <Link className={styles.tc_links} activeClassName='is-active' to='/privacy-policy/'>Privacy Policy</Link>
                    </div>
                    <div className={styles.link_section_div}>
                        <Link className={styles.tc_links} activeClassName='is-active' to='/information-security-policy/'>Information Security Policy</Link>
                    </div>
                    <div className={styles.link_section_div}>
								<Link className={styles.tc_links} activeClassName='is-active' to='/posh-policy/'>POSH Policy</Link>
					</div>
                </div>
                <div className={styles.content_section}>
                        <TermsOfServices />
                </div>
            </div>
        </Layout>
    </>
    )
}

export default TermsOfService
